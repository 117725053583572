.lading-page {
  width: 100%;
  min-height: 760px;
  background-image: url("../../../public/images/hero-bg.webp");
  background-color: rgba(5, 0, 35, 1);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @include media("<=tablet") {
    text-align: center;
  }
  .wrap {
    .hero-section {
      min-height: 630px;
      padding: 40px 0;
      gap: 30px;
      .hero-left-side {
        @include media("<=tablet") {
          margin-top: 100px;
        }
        .hero-slug {
          color: #fff;
          font-family: "Syne", sans-serif;
          font-size: 50px;
          font-style: normal;
          font-weight: 500;
          line-height: 60px;
          span {
            color: #eff3a1;
          }
        }
        .hero-desc {
          margin: 30px 0;
          color: #fff;
          font-size: 18px;
          font-style: normal;
          font-weight: 300;
          line-height: 146.023%;
        }
        .actions {
          @include media("<=tablet") {
            justify-content: center;
          }
          gap: 16px;
          .btn-icon {
            svg {
              height: 16px;
              width: 16px;
            }
          }
          .btn-lbl {
            color: #101b3a;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .btn-buy {
            display: flex;
            border-radius: 10px;
            gap: 12px;
            padding: 12px 20px;
            .btn-icon {
            }
            .btn-lbl {
            }
          }
          .btn-contract {
            display: flex;
            gap: 12px;
            background: transparent;
            border-color: #fff;
            border-radius: 10px;
            padding: 12px 20px;
            .btn-icon {
            }
            .btn-lbl {
              color: #fff;
            }
          }
        }
      }
      .hero-right-side {
        .circle {
          height: 500px;
          width: 500px;
          border-radius: 50%;
          background: rgba(14, 255, 214, 0.12);
          filter: blur(116.5px);
          @include media("<=tablet") {
            width: 300px;
          }
        }
        .map-img {
          height: 380px;
          width: 380px;
          border-radius: 50%;
        }
      }
    }
  }
}

.invest-withdraw-section {
  .invest-withdraw-block {
    gap: 40px;
    padding: 50px 0;
    @include media("<=tablet") {
      flex-direction: column;
    }
    .iw-left-side {
      flex: 1;
      @include media("<=tablet") {
        width: 100%;
      }
      .selection-tabs {
        width: 100%;
        border-radius: 20px 20px 0px 0px;
        border: 1px solid $themeColor;
        overflow: hidden;
        .tab-item {
          flex: 1;
          background: transparent;
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 146.023%;
          padding: 18px;
          overflow: hidden;
          border: none;
          @include anim;
          &.active {
            background: $themeColor;
            color: #171d30;
          }
        }
      }
      .box-block {
        min-height: 300px;
        padding: 40px;
        border-radius: 12px;
        background: #140e3a;
        .block-hdr {
          .block-slug {
            flex: 1.4;
            color: #fff;
            font-family: "Syne", sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 26px;
            span {
              color: $themeColor;
            }
          }
          .icon-side {
            flex: 1;
            .icon {
              height: 60px;
              width: 60px;
              border: 1px solid #fff;
              border-radius: 50%;
              padding: 8px;
              margin-right: 38px;
              svg {
                height: 40px;
                width: 40px;
              }
            }
          }
        }
        .input-field {
          margin: 28px 0;
          .input-lbl {
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 146.023%;
          }
          .txt {
            color: #000;
            padding: 10px;
            border-radius: 10px;
            background: #ececec;
            border: 1px solid transparent;
            &:focus {
              border-color: $themeColor;
            }
          }
        }
        .info-block {
          .info-item {
            margin-bottom: 12px;
            .lbl {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
            }
            .val {
              color: #fff;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
            }
          }
        }
        .btn-amount {
          color: #171b2f;
          font-size: 16px;
          font-weight: 500;
          font-style: normal;
          border-radius: 8px;
        }
      }
    }
    .iw-right-side {
      flex: 1;
      @include media("<=tablet") {
        width: 100%;
      }
      .info-box {
        height: 120px;
        padding: 30px;
        border-radius: 0px 35px 0px 0px;
        border: 2px solid #eff3a1;
        .lbl {
          color: #fff;
          font-size: 16px;
          font-style: normal;
          font-weight: 300;
          line-height: 146.023%;
          margin-bottom: 8px;
          @include media("<=phoneH") {
            font-size: 12px;
          }
        }
        .val {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 146.023%;
          @include media("<=phoneH") {
            font-size: 16px;
          }
        }
        .referral-val {
          color: #eff3a1;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 146.023%;
          @include media("<=phoneH") {
            font-size: 16px;
          }
        }
        .icon-copy {
          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
    }
  }
}

.investment-history-section {
  .investment-history-block {
    padding: 110px 0;
    .sec-title {
      color: #fff;
      font-family: "Syne", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 42.064px;
      margin-bottom: 50px;
    }
    .table-block {
      border-radius: 8px;
      background: #140e3a;
      min-height: 200px;
      max-height: 300px;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-thumb {
        background: #544b8b;
        border-radius: 4px;
      }
      @include media("<=tablet") {
        overflow-x: auto;
      }

      .row {
        width: 100%;
        padding: 20px;
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.023%; /* 32.125px */
        &:first-child {
          font-size: 15px;
          font-weight: 400;
          border-bottom: 1px solid rgba(255, 255, 255, 0.4);
        }
        @include media("<=tablet") {
          width: 800px;
          overflow: hidden;
          overflow-x: auto;
        }
        .row-item {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;

          &:first-child,
          &:last-child {
            flex: 0.4;
          }
        }
      }
    }
  }
}
