.ref-section {
  justify-content: center;
  align-items: center;
  text-align: center;
  @include media("<=tablet") {
    min-height: 500px;
  }
  .ref-wrap {
    .ref-heading {
      color: #fff;
      font-family: "Syne", sans-serif;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 42.064px;
      margin-bottom: 70px;
      @include media("<=tablet") {
        font-size: 22px;
        margin: 30px 0;
      }
    }
    .ref-col {
      position: absolute;
      margin: 10px auto;
      width: 600px;
      height: 500px;
      border-radius: 630px;
      background: rgba(14, 255, 214, 0.2);
      filter: blur(252.5px);
      @include media("<=tablet") {
        width: 330px;
        height: 650px;
      }
    }
    .ref-details {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 30px;
      flex-wrap: wrap;
      .ref-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
        border-radius: 0px 35px 0px 0px;
        border: 2px solid #eff3a1;
        height: auto;
        width: 300px;
        padding: 20px 0;
        @include media("<=tablet") {
        }
        .box-heading {
          font-family: "Syne", sans-serif;
          font-size: 28px;
          font-style: normal;
          font-weight: 700;
          margin-bottom: 4px;
          @include media("<=tablet") {
            font-size: 24px;
          }
        }
        .box-desc {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          @include media("<=tablet") {
            font-size: 12px;
          }
        }
      }
    }
  }
}
