// Footer
.footer-comp {
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 60px;
  padding: 10px 0px;
  background: $themeColor;
  @include media("<=phoneH") {
    min-height: 40px;
    padding: 8px 0px;
  }
  .wrap {
    .copy-right {
      color: #050023;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      @include media("<=tablet") {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
